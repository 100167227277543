<template>
  <div class="border">
    <div class="help-div">
      <h2 class="text-center">FAQ</h2>
      <div class="shadow" v-for="(data,i) in faq" :key="i">
        <h4 @click="show(i)" class="cursor p-0" style="font-size: 22px;text-align: left;">
          {{data.q}}
          <span v-if="visible(i)">
            <i class="material-icons">arrow_drop_up</i>
          </span>
          <span v-if="!visible(i)">
            <i class="material-icons">arrow_drop_down</i>
          </span>
        </h4>
        <div v-if="visible(i)">
           <!-- <div v-for="(s,j) in data.src" :key="j"> -->
          <div>
           <div v-show ="data.ans1" class="jo-faq">{{data.ans1}} </div>
           <div v-show ="data.ans19" class="jo-faq"><br><b>{{data.ans19}}</b></div>
           <div v-show ="data.ans2" class="jo-faq"><br><b v-show ="data.ans15">{{data.ans15}}</b><b v-show ="data.ans18">{{data.ans18}}</b>{{data.ans2}} </div>
           <div v-show ="data.ans3" class="jo-faq"><br><b>{{data.ans16}}</b>{{data.ans3}} </div>
           <div v-show ="data.ans20" class="jo-faq"><br><b>{{data.ans20}}</b></div>
            <ul style="list-style-type:disc;margin-left: 14px;" class="jo-faq">
            <li v-show ="data.ans9" class="jo-faq">{{data.ans9}}</li>
            <li v-show ="data.ans10" class="jo-faq">{{data.ans10}}</li> 
          </ul>
          <div v-show ="data.ans12" class="jo-faq"><br><b>{{data.ans12}}</b></div>
           <div v-show ="data.ans13" class="jo-faq"><br><b>{{data.ans13}}</b></div> 
           <div v-show ="data.ans4" class="jo-faq"><br><b>{{data.ans17}}</b>{{data.ans4}} </div>
           <div v-show ="data.ans5" class="jo-faq"><br>{{data.ans5}} </div>
           <div v-show ="data.ans6" class="jo-faq"><br>{{data.ans6}} </div>
           <div v-show ="data.ans7" class="jo-faq"><br>{{data.ans7}} </div>
           <div v-show ="data.ans8" class="jo-faq">{{data.ans8}};</div>


          <table cellspacing="0" cellpadding="0" style="border-collapse:collapse;margin-top:15px;margin-left: 9px;" v-show="data.ans11" >
             <tbody>
              <tr>
                  <td  valign="top" nowrap="nowrap" class="heading-nowrap">
                    <font  class="heading-font">Courier Name</font>
                  </td>
                    <td  valign="top" nowrap="nowrap" class="heading-nowrap">
                      <font  class="heading-font">Pickup Cut-Off Time</font>
                    </td>
                   <td  valign="top" nowrap="nowrap" class="heading-nowrap">
                      <font   class="heading-font">Customer Service No</font>
                   </td>
              </tr> 

              <tr>          
                <td  valign="top" nowrap="nowrap"  class="courier-name">
                    <font  class="courier-name-font">Australia Post</font>
                </td>
                <td  valign="top" nowrap="nowrap"></td>
                <td  valign="top" nowrap="nowrap" class="blank-row"></td>
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font class="courier-name-font">Star Track </font>
                  </td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                      <font class="courier-name-font">Toll Ipec</font>
                  </td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
                  <td  valign="top" nowrap="nowrap"class="blank-row"></td>
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font class="courier-name-font">DHL International</font>
                  </td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font  class="courier-name-font">Couriers Please</font>
                  </td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font  class="courier-name-font">Zoom2U</font>
                  </td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
                  <td  valign="top" nowrap="nowrap" class="blank-row"></td>
            </tr>
                    
        </tbody>
    </table>


            <table cellspacing="0" cellpadding="0" style="border-collapse:collapse;margin-top:15px;width: 48%;margin-left: 9px;" v-show ="data.ans14">
             <tbody>
              <tr>
                  <td  valign="top" nowrap="nowrap" class="heading-nowrap" style="text-align: center;">
                    <font  class="heading-font">Service Name</font>
                  </td>
                 
              </tr> 

              <tr>          
                <td  valign="top" nowrap="nowrap"  class="courier-name">
                    <font class="courier-name-font">Parcel Post Flat Rate Satchel 5KG (Small)</font>
                </td>
                
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font class="courier-name-font">Express Post Flat Rate Satchel 5KG (Large)</font>
                  </td>
                 
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                      <font class="courier-name-font">Express Post Flat Rate Satchel 5KG (Extra Large)</font>
                  </td>
                  
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font class="courier-name-font">Parcel Post Flat Rate Satchel 5KG (Large)</font>
                  </td>
                 
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font  class="courier-name-font">Parcel Post Flat Rate Satchel 5KG (Large)</font>
                  </td>
                  
              </tr>
              <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font class="courier-name-font">Express Post Flat Rate Satchel 5KG (Extra Large)</font>
                  </td>
                  
            </tr>

               <tr>  
                  <td  valign="top" nowrap="nowrap" class="courier-name">
                    <font  class="courier-name-font">Parcel Post Flat Rate Satchel 5KG (Large)</font>
                  </td>
                  
            </tr>
                    
        </tbody>
    </table>
         
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const faqUser = [
  {
    q: "How do I book a courier",

    ans1:"The courier booking process is a simple 3 step process.",
    ans15:"Step 1",
    ans2:" is to select the courier you want based on the type of item you are sending, domestic or international, item weight, delivery address, and the delivery’s urgency are entered. The system will then provide matching couriers with service levels and prices. The response time to display the matching couriers will depend on the selection criteria you have entered.",
    ans16:"Step 2",
    ans3:" is to confirm the delivery address by entering the mandatory fields. The system will highlight the required fields based on your selected carrier.",
    ans17:"Step 3",
    ans4:" is to print the label and dispatch.",
    ans5:"If you don’t find the courier you are looking for, please contact DBL customer service at support@digitalhub.com or 1800 000 0000 during business hours."
    
  },
  {
    q: "Can I generate a courier label",
     ans1:"Yes, you can. Once you have confirmed and dispatched a courier, you can view and print the label.  The printing is done using a standard A4 paper and is pasted on the physical shipment.",
     ans18:"Note:",
     ans2:" Make sure the label is visible to the courier",
     ans3:"As an additional option, you can print a manifest report that you can produce with the items. This is optional and generally used for high volume pickups."
  },
  {
    q: "How do I arrange a pickup",
    ans19:"Regular Shipper",
    ans2:"If you are a regular shipper, you will have an agreed daily pickup with your courier. The booking always needs to be made before this agreed pickup time.",
    ans20:"Ad-Hoc Pickup",

    ans4:"You need to ensure the courier booking is made before the courier booking cut-off time after you have booked the courier; you then need to call the courier company and arrange pickup.",

    ans5:"Below are the customer service numbers for integrated couriers for pickup confirmation.",
    ans11:"Courier Name",
  },  
  {
    q: "What paperwork do I need for international shipments",
     ans1:"For international non-documents items, you are required to complete a customs declaration. This is done when you are booking the courier by entering the item you are sending with duty, currency and value of goods.  With the label, you will be able to print the commercial invoice.",
  },
  {
    q: "Who pays for duty for international shipments",
    ans1:"Generally, the duty is paid by the receiver. However, the system allows you to override this and nominate you as the duty payer.",
  }, 
  {
    q: "How will I know when the shipments are delivered?",
    ans1:"You will receive an email when the courier delivers the shipment.  You will also have an option to request SMS notifications for urgent and high-value shipments."
  },
  {
    q: "How can I manage on-time delivery",
    ans1:"Our courier performance reports will allow you to manage the couriers on-time delivery performance easily. The data is also can be downloaded to a ‘CSV’ file. The late shipments are also can be emailed to you. "
  },
 
  {
    q: "How do I calculate volumetric for non-document shipments",
    ans1:"Volumetric weight, sometimes called dimensional weight, refers to the amount of space your package occupies on a truck or aircraft while it is being shipped. In other words, volumetric weight is your package's size—not the weight. Often, a service will measure both the volumetric weight and the parcel's actual weight; the bigger of the two numbers is then used to determine the cost.",

    ans2:"To calculate volumetrically, first, you need to determine your package’s length, width, and height. The length is always the longest side, while the width is the longer of the remaining two sides. Sometimes the width and height are the same, making them interchangeable.",

    ans3:"Once you have those measurements, simply plug them into the following equation:",
    ans9:"Length x Width x Height (in centimetres) / 5000",
    ans10:"(Rounded up to the nearest 0.5kg)",
    ans12:"For example, let’s say you’re shipping a box with a length of 30cm, a width of 25cm, and a height of 15cm. The box’s volumetric weight in kilograms is:",
    ans13:"30 x 25 x 15 / 5000 = 2.5kg",
    ans8:"Again, many courier services will compare this measurement with the parcel’s actual weight and use the larger one to calculate shipping costs. So, if our example package weighs less than 2.5kg, the shipping cost will be based on its volumetric weight. If it weighs more than 2.5kg, the shipping cost will be based on its actual weight."

  },
    {
    q: "Can I access Australia Post services?",
    ans1:"You can select Australia Post My Business services. Below is a list of My Post services:",

    ans14:"Service Name",
  },
  {
    q: "Can I track real-time",
    
  },
  {
    q: "Can I get a single invoice with matching delivery status?",
    
  },
    {
    q: "Who escalate delivery related issues with courier ",
    
  },
      {
    q: "What reports can I get",
    
  },
      {
    q: "Can I report on the cost centre?  ",
    
  },
      {
    q: "How much customisation the platform offers ",
    
  },
      {
    q: "Can I use my staff for delivery? ",
    
  },
      {
    q: "Can I consolidate documents and track them?",
    
  },

];


export default {
  name: "Help",
  data() {
    return {
      visibleQuestion: [],
      pageDepth: ["Help"],
      user: {},
      faq: faqUser
    };
  },
  created() {
    this.$emit("breadCrumbData", ["FAQ"]);
    try {
      this.auth();
    } catch (e) {
      localStorage.clear();
      this.toast.error("You must log in first");
      this.$router.push("/");
    }
    this.checkUser();
  },
  methods: {
    checkUser() {
      this.user = this.auth();
      // console.log(this.user);
      // console.log(this.user.user_type);
      // if (this.user.user_type == "super_admin") {
      //   this.faq = faqAdmin;
      // } else if(this.user.user_type == "hub_admin" ){
      //   this.faq = faqHubAdmin;
      // } else if(this.user.user_type == "customer_service_user"){
      //   this.faq = faqCSU;
      // } else {
        this.faq = faqUser;
      // }
    },
    visible(q) {
      return this.visibleQuestion.indexOf(q) >= 0;
    },
    show(q) {
      if (this.visibleQuestion.indexOf(q) >= 0) {
        this.visibleQuestion.splice(this.visibleQuestion.indexOf(q), 1);
      } else {
        this.visibleQuestion.push(q);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit("pageHierarchy", vm.pageDepth);
    });
  }
};
</script>
<style scoped>
.help-div {
  margin-top: 100px;
  padding: 1em;
}
.cursor {
  cursor: pointer;
}
.shadow {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin: 10px 0;
}
.p-0 {
  padding: 0;
  margin: 10px;
}
h2 {
  padding: 0 0 2em 0;
}
img {
  max-width: 90%;
  margin: 0;
}
.heading-nowrap
{
  background-color:#004a7c;
  width:135px;
  height:11px;
  border-style:solid;
  border-width:1px;
  border-color:rgb(170,170,170);
  padding:0px 2px 2px;
}
.heading-font
{
  font-stretch:normal;
  font-size:16px;
  line-height:normal;
  font-family:"Roboto Condensed", sans-serif;
  font-variant-ligatures:common-ligatures;
  color:#fff;
}
.courier-name
{
  background-color: white;
  width:100px;
  height:11px;
  border-style:solid;
  border-width:1px;
  border-color:rgb(170,170,170);
  padding:0px 2px 2px;
}
.courier-name-font
{
  font-stretch:normal;
  font-size:16px;
  line-height:normal;
  font-family:"Roboto Condensed", sans-serif;
  font-variant-ligatures:common-ligatures;
  color:#000000;
}
.blank-row
{
  background-color: white;
  width:400px;
  height:11px;
  border-style:solid;
  border-width:1px;
  border-color:rgb(170,170,170);
  padding:0px 2px 2px;
}
.jo-faq
{
  padding-left:9px;
}
</style>